<template>
    <div>
        <main-layout></main-layout>
        <v-container class="wrapper">
            <div class="form-group">
                <h2 class="text-center">{{ $t("membership.title") }}</h2>
                <hr />
            </div>
            <div>
                <v-row class="mt-3 mb-3 card-content">
                    <v-col class="col-sm-12 col-md-6">
                        <div class="id-card ratio ratio-16x9" >
                            <div
                                class="d-flex align-items-stretch flex-column w-100 h-100 py-4"
                            >
                                <v-row class="mb-auto align-items-start">
                                    <v-col cols="auto" class="mr-auto">
                                        <h3 class="text-white">{{ customerInfo.fullName }}</h3>
                                    </v-col>
                                    <v-col cols="auto avatar-container">
                                        <img :src="customerAvatar" d-block width="30" />
                                    </v-col>
                                </v-row>
                                <v-row class="align-items-end">
                                    <v-col cols="auto" class="mr-auto">
                                        <h4 class="text-white">
                                        ID:
                                        {{ customerInfo.customerId }}
                                        </h4>
                                        <h5 class="text-white">{{ customerInfo.createdAt }}</h5>
                                    </v-col>
                                    <v-col cols="auto">
                                        <qr-code
                                            :text="customerInfo.qrCodeString"
                                            :size="60"
                                        ></qr-code>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </v-col>
                    <v-col class="col">
                        <v-row class="my-2" v-if="customerInfo.level != null">
                            <v-col cols="6">
                                <h6>{{ $t("membership.loyaltyLevel") }}</h6>
                            </v-col>
                            <v-col>
                                <span class="text-right d-block">{{ customerInfo.levelName }}</span>
                            </v-col>
                        </v-row>
                        <v-row class="my-2">
                            <v-col cols="6">
                                <h6>{{ $t("membership.totalPoints") }}</h6>
                            </v-col>
                            <v-col>
                                <h6 class="text-right">
                                    {{ customerInfo.totalPoints }}
                                </h6>
                            </v-col>
                        </v-row>
                        <v-row class="my-2">
                            <v-col cols="6">
                                <h6>{{ $t("membership.availablePoints") }}</h6>
                            </v-col>
                            <v-col>
                                <h6 class="text-right">
                                    {{ customerInfo.availablePoints }}
                                </h6>
                            </v-col>
                        </v-row>
                        <hr />
                        <v-row class="my-2">
                            <button @click="toTransactionHistory()" class="btn btn-outline-secondary btn-block">
                                {{ $t("transactionHistory.title") }}
                            </button>
                        </v-row>
                    </v-col>
                </v-row>
                <hr />
            </div>
        </v-container>
        <loader v-show="loading"></loader>
    </div>
</template>
<script>
import MainLayout from "@/layout/MainLayout.vue";
import screen from "@/mixins/screen.js";
import { mapGetters, mapState, mapActions } from "vuex";
import Loader from "@/components/Loader.vue";
import VueQRCodeComponent from "vue-qrcode-component";

export default {
    name: "Membership",
    components: {
        Loader,
        MainLayout,
        "qr-code": VueQRCodeComponent,
    },
    mixins: [screen],

    data() {
        return {
            loading: false,
        };
    },

    // beforeRouteEnter(to, from, next) {
    //     window.scrollTo(0, 0);
    //     store.state.auth.allowAnonymous = false;
    //     if (store.state.setting.useMembership) {
    //         next();
    //     } else {
    //         next("/");
    //     }
    // },

    computed: {
        ...mapState(["auth"]),
        ...mapGetters(["brandInfo"]),
        ...mapGetters({
            authDataGet: "auth/authDataGet",
            customerInfo: "customer/customerInfo",
        }),
        customerAvatar() {
            if (
                !this.customerInfo.isDisplayAvatar ||
                this.customerInfo.avatarUrl == null
            ) {
                return require('../assets/images/user_avatar.png');
            } else {
                return this.customerInfo.avatarUrl;
            }
        },
    },
    mounted() { },

    created() {
        this.$nextTick(async () => {
            this.loading = true;
            try {
                await this.fetchCustomerData({ id: this.authDataGet.id, brandInfo: {brandId: 51} });
            } finally {
                this.loading = false;
            }
        });
    },

    methods: {
        ...mapActions({
            fetchCustomerData: "customer/fetchCustomerData",
        }),
        toTransactionHistory() {
            this.$router.push("/transaction-history");
        },
    },
};
</script>
  
<style lang="scss" scoped>
h1, h2, h3, h4, h5, h6 {
    margin: 0;
}
.id-card {
    background: url(../assets/images/id_card_bg.jpg) no-repeat;
    border-radius: 1rem;
    background-size: cover;
    max-width: 500px;
    margin: auto;
}

.background-white {
    background: white;
}
.row {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
}
.col {
    padding: 0 15px;
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
}
// .col-md-6 {
//     padding-left: 0;
//     padding-right: 0;
// }
.my-2 {
    margin: 5px 0 !important;
}
.py-4 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}
.card-content {
    flex-direction: column;
}
.mr-auto, .mx-auto {
    margin-right: auto!important;
}
.col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}
.avatar-container {
	line-height: 0;		
	display: inline-block;
	margin: 0 15px;
	border-radius: 50%;
    background-color: #dfdfdf;
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}
h4 {
    font-size: 16px;
}
h5 {
    font-size: 14px;
}
.container {
    padding-right: 0;
    padding-left: 0;
}
@media (min-width: 576px) {
    .card-content {
        flex-direction: row;
    }
    h4 {
        font-size: 20px;
    }
    h5 {
        font-size: 18px;
    }
}
</style>